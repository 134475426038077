<template>
  <LayoutDefault>
    <Loading :active="loading" />
    <div class="content-wrapper news">
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Entrenamiento</h1>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-sm-6">
              <router-link
                :to="{ name: 'trainingCreate' }"
                class="btn btn-default btn-default-invert float-right ml-2 mb-2 mb-md-0"
              >
                Crear Media
              </router-link>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-md-3" v-if="!loading">
            <div
              class="col-xs-12 col-sm-6 col-md-4"
              v-for="(el, index) in media"
              :key="index"
            >
              <div class="card" style="width: 18rem">
                <img class="card-img-top" :src="el?.img_url" alt="" />
                <div class="card-body">
                  <h5 class="card-title">{{ el.title }}</h5>
                  <p class="card-text">{{ el.description }}</p>
                  <p class="card-text mt-1">
                    Created: {{ formatDate(el.created_at) }}
                  </p>
                  <router-link
                    :to="{ name: 'trainingEdit', params: { new_id: el.id } }"
                    class="btn-edit btn btn-info"
                  >
                    <i class="nav-icon fas fa-edit"></i>
                  </router-link>
                  <button
                    @click="deleteMediaContent(el.id)"
                    class="btn-delete btn btn-danger"
                  >
                    <i class="nav-icon fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <!-- <p class="card-text mt-1">{{ el.id }}</p>
              <p class="card-text mt-1">{{ formatDate(el.created_at) }}</p>
              <p class="card-text mt-1">{{ el.img_url }}</p>
              <p class="card-text mt-1">{{ el.description }}</p>
              <p class="card-text mt-1">{{ el.title }}</p>
              <p class="card-text mt-1">{{ el.type }}</p>
              <p class="card-text mt-1">{{ el.url_streaming }}</p>
              <p class="card-text mt-1">{{ formatDate(el.updated_at) }}</p> -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.container-fluid -->
        </div>
      </div>
    </div>
  </LayoutDefault>
</template>

<script>
import "datatables.net";
import LayoutDefault from "@/layout/LayoutAdmin";
import Loading from "../components/Loading";
import trainingService from "@/services/trainingService";
export default {
  name: "training",
  data() {
    return {
      media: [],
      edit: false,
      loading: true,
    };
  },
  async mounted() {
    this.loading = true;
    await this.getAllMediaContent();
    this.loading = false;
  },
  methods: {
    formatDate(date) {
      const _date = new Date(date);
      let _month = "" + (_date.getMonth() + 1);
      let _day = "" + _date.getDate();
      const _year = _date.getFullYear();
      if (_month.length < 2) _month = "0" + _month;
      if (_day.length < 2) _day = "0" + _day;
      return [_year, _month, _day].join("/");
    },
    async deleteMediaContent(id) {
      this.loading = true;
      await trainingService.deleteTrainingItem(id);
      await this.getAllMediaContent();
      this.loading = false;
    },
    // userService.getMediaContentData();
    async getAllMediaContent() {
      const res = await trainingService.getTrainingDataWebinar();
      console.dir(res?.data);
      this.media = res?.data;
    },
    // userService.getMediaContentById(1);
    // ...
    // userService.getMediaContentTypes();
    // ...
  },
  components: { LayoutDefault, Loading },
};
</script>
import config from "@/config/config";
import Axios from "axios";

export default new class TrainingService {
  private config = config.getConfig();
  getToken() {
    return localStorage.getItem('__token');
  }

  // GET WEBINARS
  async getTrainingDataWebinar() {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/media-content/webinar`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // GET PODCAST
  async getTrainingDataPodcast() {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/media-content/podcast`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // GET Training Types
  async getTrainingTypes() {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/media-content-types`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // GET Training Item
  async getTrainingItem($id: any) {
    const token = this.getToken();
    try {
      return (await Axios.get(`${this.config.apiUrl}/media-content-item/${$id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // DELETE Training Item
  async deleteTrainingItem($id: any) {
    const token = this.getToken();
    try {
      return (await Axios.delete(`${this.config.apiUrl}/media-content/${$id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // UPDATE Training Item
  async updateTrainingItem($id: any, $data: any) {
    const token = this.getToken();
    try {
      return (await Axios.put(`${this.config.apiUrl}/media-content/${$id}`, $data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

  // POST Create Training
  async createTrainingItem(data: any) {
    const token = this.getToken();
    try {
      return (await Axios.post(`${this.config.apiUrl}/media-content`, data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })).data;
    } catch (e) {
      if (!e.response) {
        // network error
        return { network: "Network Error" };
      }
      return e.response.data;
    }
  }

}